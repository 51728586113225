th, td{
  font-size: 20px;
  width: 250px;
}


ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.appBar{
  display: flex;
  flex-flow: row nowrap;
  background-color: black;
  align-items: center;
  justify-content: space-between;
}

.appTitle{
  padding: 4px;
  font-size: 20px;
  color: turquoise;
}

.navList{
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.tableTitle{
  display:block;
  margin: 20px auto 20px auto;
  text-align:center;
  align-items: center;
  font-size: 28px;
  font-weight: bolder;
  color: blue;
}

.listView{
  margin-top: 50px;
  margin-bottom: 50px;
}

.linkList{
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
  margin-right: 20px;
}

.linkItem{
  margin: 6px 0 6px 0;  
}
  
.link{
  color: turquoise;
  font-weight: bolder;
  font-size: 18px;
  padding: 6px;
}  


.linkBtn{
  background-color: aquamarine;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid blue;
 
}

.linkBtn{
  color: black;
  font-weight: bolder;
}

.mainView{
  display: grid;
  grid-template-areas: 
  "frm view view"
  "frm view view"
  "frm view view";
  gap: 30px;

}


.devicesTable{
  border:2px solid black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}



.tblHead{
  background-color: lightskyblue;
  font-size: 18px;
}

.inputLabel{
  font-size: 18px;
}

.intakeForm{
  display: flex;
  flex-flow: row nowrap;
  gap:50px;
}


.deviceForms{
  grid-area: frm;
}

.viewDevice{
  grid-area: view;
}

.dataView{
  display:block;
}

.cardView{
  
  display: flex;
  flex-flow: column nowrap;
  margin: 16px 0 16px 0;
  gap: 10px;
  border: 1px solid grey;
  padding: 10px;
  width:300px;
}

.deviceCard{
  display: block;
}

.deviceHeader{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  background-color: aqua;
  align-items: center;
}

.deviceTitle{
  font-size: 24px;
  font-weight: bolder;
  text-align: left;

}

.deviceBody{
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.infoInput{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 20px;
}

.inputBlock{
  margin-left: auto;
  margin-right: auto;
  
}

.inputFields{
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  padding: 6px;
  width: 350px;
  
}


.inputLabel{
  font-weight: bolder;
}

.trackingInfo{
  display:flex;
  flex-flow: column nowrap;
  gap: 6px;
  
}

.cardButtons button{
  border-radius: 10px;
  border: 2px solid darkgrey;
  color: white;
  font-weight: bolder;
  font-size: 16px;
  background-color: blue;
  margin-left: 20px;
  margin-right: 20px;
}

.loginForm, .signupForm{
  width: 400px;
  background-color: lightgrey;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

.formGrid{
  display: flex;
  flex-flow: row nowrap;
  

}
.formView{
  display: block;
  margin: 20px auto 20px auto;

}



.formTitle{
  display:block;
  margin: 20px auto 20px auto;
  padding: 6px;
  text-align: center;
  font-weight: bolder;
  font-size: 24px;
  color: Blue;
}

.formRow{
  display:grid;
  font-size: 18px;
}
.notesArea{
  color: blue;
  min-height: 50px;
}

.formRow Label{
  text-align: left;
  padding: 6px;
  font-size: 20px;
}

.formRow input{
  padding: 6px;
  font-size: 20px;
}

.formRow select{
  padding: 6px;
  font-size: 20px;
}

.formRow textarea{
  padding: 6px;
  font-size: 20px;
  height: 60px;
}

.formFields{
  display: grid;
  font-size: 24px;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
}

.formBtn{
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  
}

.submitBtn{
  font-size: 16px;
  font-weight: bold;
  border: 2px solid black;
  border-radius: 6px;
  padding: 6px;
  width: 150px;
  display: block;
  margin: 20px auto 20px auto;
}

.pageTitle{
  display:block;
  text-align: center;
  margin-top: 20px;
}


